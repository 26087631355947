import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {NavbarComponent} from '@modules/shared/ui/navbar/navbar.component';
import {FooterComponent} from '@modules/shared/ui/footer/footer.component';
import {RouterLink} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import { PromoCodeBannerComponent } from './promo-code-banner/promo-code-banner.component';
import {PipesModule} from '@core/pipes/pipes.module';
import {AdsModule} from '@modules/shared/ad/ads.module';
import { FeatureBannerComponent } from './feature-banner/feature-banner.component';
import { FeatureBannerCtaComponent } from './feature-banner-cta/feature-banner-cta.component';
import { BuyMeACoffeeComponent } from './buy-me-a-coffee/buy-me-a-coffee.component';


@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PromoCodeBannerComponent,
    FeatureBannerComponent,
    FeatureBannerCtaComponent,
    BuyMeACoffeeComponent
  ],
    imports: [
        CommonModule,
        RouterLink,
        NgOptimizedImage,
        ReactiveFormsModule,
        PipesModule,
        AdsModule
    ],
  exports: [
    NavbarComponent,
    FooterComponent,
    PromoCodeBannerComponent,
    FeatureBannerComponent,
    FeatureBannerCtaComponent,
    BuyMeACoffeeComponent
  ]
})
export class UiModule {
}
