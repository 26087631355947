<nav class="navbar navbar-expand-lg navbar-light sticky-top oa-navbar oa-nav-transition navbar-text py-4" id="navbar"
     role="navigation" aria-label="Main Navigation">
  <div class="container">
    <!-- Logo -->
    <div class="mat-h1 mb-0">
      <a routerLink="home" aria-label="Origin Audio Home">
        <img ngSrc="/assets/images/logo/logowhitebg.jpg" alt="Origin Audio Logo" width="100" height="50">
      </a>
    </div>

    <!-- Hamburger Menu Button -->
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            id="hamburger-menu" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Navigation Links -->
    <div class="collapse navbar-collapse px-0" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">

        <li ngbDropdown class="nav-item dropdown">
          <a ngbDropdownToggle class="nav-link dropdown-toggle" id="navbarDarkDropdownMenuLink" aria-expanded="false"
             style="cursor:pointer">
            Tools
          </a>
          <ul ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
            <li><a ngbDropdownItem class="dropdown-item" routerLink="normalizer" aria-label="Normalizer">Normalizer</a>
            </li>
            <li><a ngbDropdownItem class="dropdown-item" routerLink="file-converter" aria-label="Audio Converter">Audio
              Converter</a></li>
            <li><hr class="dropdown-divider"></li> <!-- Separator -->
            <li><a ngbDropdownItem class="dropdown-item" routerLink="auto-slicer" aria-label="AI Auto Slicer">AI Auto
              Slicer</a></li>
            <li><a ngbDropdownItem class="dropdown-item" routerLink="stem-isolator" aria-label="Stem Isolator">AI Stem
              Isolator</a></li>
            <li><a ngbDropdownItem class="dropdown-item" routerLink="drum-organizer" aria-label="Drum Organizer">AI Drum
              Organizer</a></li>
          </ul>
        </li>

        <!-- Pricing Link -->
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="pricing" aria-label="Features Page">
              Features
            </a>
          </div>
        </li>

        <!-- About Link -->
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="about" aria-label="About Origin Audio">
              About
            </a>
          </div>
        </li>

        <!-- Blog Link -->
        <li class="nav-item px-3">
          <div class="mat-h1 my-auto">
            <a class="nav-link" routerLink="articles"
               aria-label="Visit Origin Audio Blog">
              Blog
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
